<div class="govuk-accordion" data-module="govuk-accordion" data-govuk-accordion-init="">
  <div class="govuk-accordion__controls">
    <button type="button" class="govuk-accordion__show-all" [attr.aria-expanded]="isShowingAll" (click)="toggleAll()">
      <span
        class="govuk-accordion-nav__chevron"
        [class]="{
          'govuk-accordion-nav__chevron--up': isShowingAll,
          'govuk-accordion-nav__chevron--down': !isShowingAll
        }"
      ></span
      ><span class="govuk-accordion__show-all-text">{{ isShowingAll ? 'Hide' : 'Show' }} all {{ contentName }}</span>
    </button>
  </div>
  <ng-content></ng-content>
</div>

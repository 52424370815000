<div
  class="govuk-button-group govuk__align-items-unset"
  data-testid="button-container"
  [ngClass]="{ 'govuk-!-margin-top-2': marginTop2, 'govuk-!-margin-top-4': marginTop4 }"
>
  <ng-container *ngIf="!return; else summary">
    <div>
      <button type="submit" class="govuk-button govuk-!-margin-right-9" (click)="onButtonClick('continue', true)">
        {{ callToAction }}
      </button>
    </div>

    <div *ngIf="recordSummary" class="govuk-!-margin-left-9 govuk-!-margin-right-9">
      <span class="govuk-visually-hidden">or</span>
      <a class="govuk-button govuk-button--link" (click)="onLinkClick($event, 'skip', false)">Skip this question</a>
    </div>

    <div *ngIf="canExit" class="govuk-!-margin-left-9 govuk-!-margin-right-9">
      <span class="govuk-visually-hidden">or</span>
      <a class="govuk-button govuk-button--link" (click)="onLinkClick($event, 'exit', false)">{{ exitText }}</a>
    </div>
  </ng-container>
  <ng-template #summary>
    <ng-container *ngIf="continue; else saveAndContinue">
      <button type="submit" class="govuk-button govuk-!-margin-right-9" (click)="onButtonClick('continue', true)">
        Continue
      </button>
    </ng-container>
    <ng-template #saveAndContinue>
      <button type="submit" class="govuk-button govuk-!-margin-right-9" (click)="onButtonClick('return', true)">
        Save and return
      </button>
    </ng-template>
    <span class="govuk-visually-hidden">or</span>
    <a class="govuk-button govuk-button--link govuk-!-margin-left-9" (click)="onLinkClick($event, 'return', false)">{{
      exitText
    }}</a>
  </ng-template>
</div>

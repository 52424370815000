<app-error-summary
  *ngIf="submitted && form.invalid"
  [formErrorsMap]="formErrorsMap"
  [serverError]="serverError"
  [form]="form"
>
</app-error-summary>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <form #formEl (ngSubmit)="onSubmit()" [formGroup]="form">
      <fieldset class="govuk-fieldset">
        <div class="govuk-form-group govuk-!-margin-bottom-2">
          <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
            <span class="govuk-caption-l" data-testid="section-heading">{{ section }}</span>
            <h1 class="govuk-fieldset__heading">{{ heading }}</h1>
          </legend>
        </div>

        <div [class.govuk-form-group--error]="submitted && form.invalid">
          <app-accordion-group #accordion data-testid="selectJobRolesAccordion" contentName="job roles">
            <p *ngIf="submitted && form.invalid" id="selectedJobRoles-error" class="govuk-error-message">
              <span class="govuk-visually-hidden">Error:</span> {{ errorMessageOnEmptyInput }}
            </p>
            <app-accordion-section
              *ngFor="let group of jobGroups"
              [title]="group.title"
              [description]="group.descriptionText"
              [expandedAtStart]="jobGroupsToOpenAtStart.includes(group.title)"
            >
              <ng-container *ngFor="let job of group.items">
                <div class="govuk-checkboxes__item">
                  <input
                    type="checkbox"
                    class="govuk-checkboxes__input"
                    [id]="'job-' + job.id"
                    [name]="job.label"
                    [value]="job.id"
                    [checked]="form.get('selectedJobRoles').value.includes(job.id)"
                    (change)="onCheckboxClick($event.target)"
                  />
                  <label class="govuk-label govuk-checkboxes__label" [for]="'job-' + job.id"> {{ job.label }} </label>
                </div>
              </ng-container>
            </app-accordion-section>
          </app-accordion-group>
        </div>
      </fieldset>

      <app-workplace-submit-button
        [return]="!!return"
        [continue]="true"
        (clicked)="setSubmitAction($event)"
        [callToAction]="return ? 'Continue' : 'Save and continue'"
        [recordSummary]="false"
      ></app-workplace-submit-button>
    </form>
  </div>

  <div class="govuk-grid-column-one-third">
    <div *ngIf="!return" class="govuk-util__float-right">
      <app-progress-bar
        data-testid="progress-bar"
        [sections]="workplaceFlowSections"
        [currentSection]="section"
      ></app-progress-bar>
    </div>
  </div>
</div>

<div class="govuk-width-container govuk-!-margin-top-0 govuk-!-margin-bottom-4 summary-box" data-testid="summaryPanel">
  <ng-container *ngFor="let section of sections; index as index">
    <div class="summary-section" [attr.data-testid]="section.fragment + '-row'">
      <div class="govuk-!-margin-top-3 govuk-!-margin-bottom-3 govuk-!-font-size-24 summary-section__title">
        {{ section.title }}
      </div>
      <div class="govuk-!-margin-top-3 govuk-!-margin-bottom-3 govuk-body summary-section__summary-text">
        <ng-container *ngIf="section.eligibility; else redFlag">
          <img
            src="/assets/images/large-green-tick.svg"
            alt="green-tick"
            class="govuk-!-margin-right-2"
            data-testid="green-tick"
          />
          <ng-container *ngIf="section.showLink; else noLink">
            <a
              (click)="onClick($event, section.fragment)"
              href="#"
              class="govuk-link--no-visited-state"
              data-testid="met-funding-message"
            >
              {{ section.meetingMessage }}
            </a>
          </ng-container>
          <ng-template #noLink>
            {{ section.meetingMessage }}
          </ng-template>
        </ng-container>
        <ng-template #redFlag>
          <img
            src="/assets/images/large-red-flag.svg"
            alt="action-required-red-flag"
            class="govuk-!-margin-right-2"
            data-testid="red-flag"
          />
          <ng-container *ngIf="section.showLink; else noLink">
            <a
              (click)="onClick($event, section.fragment)"
              href="#"
              class="govuk-link--no-visited-state"
              data-testid="not-met-funding-message"
            >
              {{ section.notMeetingMessage }}
            </a>
          </ng-container>
          <ng-template #noLink>
            {{ section.notMeetingMessage }}
          </ng-template>
        </ng-template>
      </div>
    </div>
    <ng-container *ngIf="index < sections.length - 1">
      <hr class="govuk-section-break govuk-section-break--visible govuk-!-margin-left-4 govuk-!-margin-right-4" />
    </ng-container>
  </ng-container>
</div>

<h2 class="govuk-heading-m govuk-!-padding-bottom-3 govuk-!-margin-bottom-0 govuk-section-break--visible">
  Your workplace details
</h2>
<dl *ngIf="workplace" class="govuk-summary-list asc-summary-list--wide-value">
  <div
    class="govuk-summary-list__row govuk-summary-list__row--no-bottom-border govuk-summary-list__row--no-bottom-padding"
    data-testid="workplace-section"
  >
    <dt class="govuk-summary-list__key">
      <div>Name</div>
    </dt>
    <dd class="govuk-summary-list__value">
      <div>{{ workplace.name }}</div>
    </dd>
    <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="''"
        [link]="['/workplace', workplace.uid, 'update-workplace-details']"
        [hasData]="workplace.name"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <div class="govuk-summary-list__row" data-testid="address-section">
    <dt class="govuk-summary-list__key">
      <div *ngIf="workplace && workplace.address">Address</div>
    </dt>
    <dd class="govuk-summary-list__value">
      <div *ngIf="workplace" class="govuk-!-padding-top-2">
        <div *ngIf="workplace.address1">{{ workplace.address1 }}</div>
        <div *ngIf="workplace.address2">{{ workplace.address2 }}</div>
        <div *ngIf="workplace.address3">{{ workplace.address3 }}</div>
        <div *ngIf="workplace.town">{{ workplace.town }}</div>
        <div *ngIf="workplace.county">{{ workplace.county }}</div>
        <div *ngIf="workplace.postcode">{{ workplace.postcode }}</div>
      </div>
    </dd>
  </div>
  <div class="govuk-summary-list__row" *ngIf="workplace?.isRegulated" data-testid="cqcLocationId">
    <dt class="govuk-summary-list__key">CQC location ID</dt>
    <dd class="govuk-summary-list__value">
      {{ workplace.locationId }}
    </dd>
    <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
      <a [routerLink]="['/workplace', workplace.uid, 'regulated-by-cqc']" (click)="setReturn()"> Change </a>
    </dd>
  </div>

  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border':
        showWdfConfirmations.numberOfStaff ||
        workplace.wdf?.numberOfStaff?.isEligible === Eligibility.NO ||
        showTotalStaffWarning
    }"
    data-testid="numberOfStaff"
  >
    <dt class="govuk-summary-list__key">Number of staff</dt>
    <dd class="govuk-summary-list__value">
      {{ workplace.numberOfStaff | numericAnswer }}
    </dd>
    <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' total number of staff'"
        [link]="['/workplace', workplace.uid, 'total-staff']"
        [hasData]="isNumber(workplace.numberOfStaff)"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-field-confirmation
    *ngIf="showWdfConfirmations.numberOfStaff && workplace.numberOfStaff === workerCount"
    [staffRecordView]="true"
    [changeLink]="getRoutePath('total-staff', wdfView)"
    (fieldConfirmation)="this.confirmField('numberOfStaff')"
    (setReturnClicked)="this.setReturn()"
  ></app-wdf-field-confirmation>
  <app-wdf-warning-message
    *ngIf="workplace.wdf?.numberOfStaff?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="numberOfStaffWdfWarning"
  ></app-wdf-warning-message>
  <app-wdf-staff-mismatch-message
    data-testid="staffMismatchMessage"
    *ngIf="showTotalStaffWarning"
    [workplace]="workplace"
    [workerCount]="workerCount"
    [overallWdfEligibility]="overallWdfEligibility"
  ></app-wdf-staff-mismatch-message>

  <div
    class="govuk-summary-list__row"
    [ngClass]="{
      'govuk-summary-list__row--no-bottom-border': workplace.wdf?.employerType?.isEligible === Eligibility.NO,
      }"
    data-testid="employerType"
  >
    <dt class="govuk-summary-list__key">Employer type</dt>
    <dd class="govuk-summary-list__value">
      <ng-container *ngIf="!(workplace.employerType?.other || workplace.employerType?.value); else employertype">
        -
      </ng-container>
      <ng-template #employertype>
        {{ workplace.employerType?.other ? workplace.employerType.other : workplace.employerType.value }}
      </ng-template>
    </dd>
    <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="'employer type'"
        [link]="['/workplace', workplace.uid, 'type-of-employer']"
        [hasData]="workplace.employerType?.other || workplace.employerType?.value"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
  <app-wdf-warning-message
    *ngIf="workplace.wdf?.employerType?.isEligible === Eligibility.NO"
    [overallWdfEligibility]="overallWdfEligibility"
    data-testid="employerTypeWdfWarning"
  ></app-wdf-warning-message>

  <ng-container>
    <div class="govuk-summary-list__row" data-testid="services-section">
      <dt class="govuk-summary-list__key">
        <h2
          class="govuk-heading-m govuk-!-margin-bottom-2"
          [ngClass]="{ 'govuk-!-margin-top-5': !removeServiceSectionMargin }"
        >
          Services
        </h2>
      </dt>
      <dd class="govuk-summary-list__value"></dd>
      <dd class="govuk-summary-list__actions"></dd>
    </div>
    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-panel--light-blue': cqcStatusRequested,
        'govuk-summary-list__row--no-bottom-border': showWdfConfirmations.mainService || (workplace.wdf?.mainService?.isEligible === Eligibility.NO),
      }"
      data-testid="mainService"
    >
      <dt class="govuk-summary-list__key" [ngClass]="{ 'govuk-!-padding-left-3': cqcStatusRequested }">Main service</dt>
      <dd class="govuk-summary-list__value" data-testid="main-service-name">
        {{ cqcStatusRequested ? requestedServiceName : workplace.mainService?.name }}
        <ng-container
          *ngIf="
            (cqcStatusRequested && requestedServiceOtherName) || (workplace.mainService?.other && !cqcStatusRequested)
          "
        >
          - {{ cqcStatusRequested ? requestedServiceOtherName : workplace.mainService?.other }}
        </ng-container>
      </dd>
      <dd
        *ngIf="canEditEstablishment"
        class="govuk-summary-list__actions"
        [ngClass]="{ 'govuk-!-padding-right-3': cqcStatusRequested }"
        data-testid="main-service-change-or-pending"
      >
        <ng-container>
          <a
            *ngIf="!cqcStatusRequested; else cqcPending"
            [routerLink]="['/workplace', workplace.uid, 'main-service-cqc']"
            (click)="setReturn()"
          >
            <ng-container *ngIf="!workplace.mainService">
              Provide information
              <span class="govuk-visually-hidden"> for</span>
            </ng-container>
            <ng-container *ngIf="workplace.mainService"> Change </ng-container>
            <span class="govuk-visually-hidden"> main service</span>
          </a>
          <ng-template #cqcPending>
            <ng-container> Pending </ng-container>
            <span class="govuk-visually-hidden"> main service</span>
          </ng-template>
        </ng-container>
      </dd>
    </div>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.mainService"
      [changeLink]="getRoutePath('main-service-cqc', wdfView)"
      [staffRecordView]="true"
      (fieldConfirmation)="this.confirmField('mainService')"
      (setReturnClicked)="this.setReturn()"
    >
    </app-wdf-field-confirmation>
    <app-wdf-warning-message
      *ngIf="workplace.wdf?.mainService?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="mainServiceWdfWarning"
    ></app-wdf-warning-message>

    <div class="govuk-summary-list__row" data-testid="otherServices">
      <dt class="govuk-summary-list__key">Other services</dt>
      <dd class="govuk-summary-list__value">
        <ng-container [ngSwitch]="workplace.otherServices.value">
          <span *ngSwitchCase="null"> - </span>
          <span *ngSwitchCase="'No'"> None </span>
          <div *ngSwitchCase="'Yes'">
            <ul class="govuk-list govuk-!-margin-bottom-0">
              <ng-container *ngFor="let service of filterAndSortOtherServices(workplace.otherServices.services)">
                <li>
                  {{ service.name }}<ng-container *ngIf="service.other">: {{ service.other }}</ng-container>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' other services'"
          [link]="['/workplace', workplace.uid, 'other-services']"
          [hasData]="workplace.otherServices.value !== null"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>

    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          showWdfConfirmations.capacities || workplace.wdf?.capacities?.isEligible === Eligibility.NO
      }"
      data-testid="serviceCapacity"
    >
      <dt class="govuk-summary-list__key">Service capacity</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.capacities?.length; else capacities"> - </ng-container>
        <ng-template #capacities>
          <ul class="govuk-list govuk-!-margin-bottom-0">
            <li *ngFor="let capacity of capacityMessages">{{ capacity.message }}</li>
          </ul>
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' service capacities'"
          [link]="['/workplace', workplace.uid, 'capacity-of-services']"
          [hasData]="workplace.capacities?.length"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.capacities"
      [staffRecordView]="true"
      [changeLink]="getRoutePath('capacity-of-services', wdfView)"
      (fieldConfirmation)="this.confirmField('capacities')"
      (setReturnClicked)="this.setReturn()"
    >
    </app-wdf-field-confirmation>
    <app-wdf-warning-message
      *ngIf="workplace.wdf?.capacities?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      warningMessage="Add the capacity of your main service"
      data-testid="capacitiesWdfWarning"
    ></app-wdf-warning-message>

    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          showWdfConfirmations.serviceUsers || workplace.wdf?.serviceUsers?.isEligible === Eligibility.NO
      }"
      data-testid="serviceUsers"
    >
      <dt class="govuk-summary-list__key">Service users</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.serviceUsers?.length; else serviceUsers"> - </ng-container>
        <ng-template #serviceUsers>
          <ul class="govuk-list govuk-!-margin-bottom-0">
            <li *ngFor="let users of workplace.serviceUsers">
              {{ users.service }}
              <ng-container *ngIf="users.other"> - {{ users.other }}</ng-container>
            </li>
          </ul>
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' service users'"
          [link]="['/workplace', workplace.uid, 'service-users']"
          [hasData]="workplace.serviceUsers?.length"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.serviceUsers"
      [staffRecordView]="true"
      [changeLink]="getRoutePath('service-users', wdfView)"
      (fieldConfirmation)="this.confirmField('serviceUsers')"
      (setReturnClicked)="this.setReturn()"
    ></app-wdf-field-confirmation>
    <app-wdf-warning-message
      *ngIf="workplace.wdf?.serviceUsers?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="serviceUsersWdfWarning"
    ></app-wdf-warning-message>
  </ng-container>

  <ng-container>
    <div class="govuk-summary-list__row" data-testid="vacancies-and-turnover-section">
      <dt class="govuk-summary-list__key">
        <h2 class="govuk-heading-m govuk-!-margin-bottom-2 govuk-!-margin-top-5">Vacancies and turnover</h2>
      </dt>
      <dd class="govuk-summary-list__value"></dd>
      <dd class="govuk-summary-list__actions"></dd>
    </div>
    <div
      class="govuk-summary-list__row"
      data-testid="vacancies"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          showWdfConfirmations.vacancies || workplace.wdf?.vacancies?.isEligible === Eligibility.NO
      }"
    >
      <dt class="govuk-summary-list__key">Current staff vacancies</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.vacancies?.length; else vacancies"> - </ng-container>
        <ng-template #vacancies>
          <ng-container *ngIf="isArray(workplace.vacancies)">
            <ul class="govuk-list govuk-!-margin-bottom-0">
              <li *ngFor="let vacancy of workplace.vacancies">{{ vacancy | formatSLV }}</li>
            </ul>
          </ng-container>
          <ng-container *ngIf="!isArray(workplace.vacancies)">{{ workplace.vacancies }} </ng-container>
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' staff vacancies'"
          [link]="['/workplace', workplace.uid, 'do-you-have-vacancies']"
          [hasData]="workplace.vacancies?.length"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.vacancies"
      [staffRecordView]="true"
      [changeLink]="getRoutePath('vacancies', wdfView)"
      (fieldConfirmation)="this.confirmField('vacancies')"
      (setReturnClicked)="this.setReturn()"
    >
    </app-wdf-field-confirmation>
    <app-wdf-warning-message
      *ngIf="workplace.wdf?.vacancies?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="vacanciesWdfWarning"
    ></app-wdf-warning-message>

    <div
      class="govuk-summary-list__row"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          showWdfConfirmations.starters || workplace.wdf?.starters?.isEligible === Eligibility.NO
      }"
      data-testid="starters"
    >
      <dt class="govuk-summary-list__key">New starters in the last 12 months</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.starters?.length; else starters"> - </ng-container>
        <ng-template #starters>
          <ng-container *ngIf="isArray(workplace.starters)">
            <ul class="govuk-list govuk-!-margin-bottom-0">
              <li *ngFor="let starter of workplace.starters">{{ starter | formatSLV }}</li>
            </ul>
          </ng-container>
          <ng-container *ngIf="!isArray(workplace.starters)">
            {{ workplace.starters }}
          </ng-container>
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' new starters'"
          [link]="['/workplace', workplace.uid, 'do-you-have-starters']"
          [hasData]="workplace.starters?.length"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.starters"
      [changeLink]="getRoutePath('starters', wdfView)"
      [staffRecordView]="true"
      (fieldConfirmation)="this.confirmField('starters')"
      (setReturnClicked)="this.setReturn()"
    >
    </app-wdf-field-confirmation>
    <app-wdf-warning-message
      *ngIf="workplace.wdf?.starters?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="startersWdfWarning"
    ></app-wdf-warning-message>

    <div
      class="govuk-summary-list__row"
      data-testid="leavers"
      [ngClass]="{
        'govuk-summary-list__row--no-bottom-border':
          showWdfConfirmations.leavers || workplace.wdf?.leavers?.isEligible === Eligibility.NO
      }"
    >
      <dt class="govuk-summary-list__key">Staff leavers in the last 12 months</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.leavers?.length; else leavers"> - </ng-container>
        <ng-template #leavers>
          <ng-container *ngIf="isArray(workplace.leavers)">
            <ul class="govuk-list govuk-!-margin-bottom-0">
              <li *ngFor="let leaver of workplace.leavers">{{ leaver | formatSLV }}</li>
            </ul>
          </ng-container>
          <ng-container *ngIf="!isArray(workplace.leavers)">
            {{ workplace.leavers }}
          </ng-container>
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' leavers'"
          [link]="['/workplace', workplace.uid, 'do-you-have-leavers']"
          [hasData]="workplace.leavers?.length"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
    <app-wdf-field-confirmation
      *ngIf="showWdfConfirmations.leavers"
      [staffRecordView]="true"
      [changeLink]="getRoutePath('leavers', wdfView)"
      (fieldConfirmation)="this.confirmField('leavers')"
      (setReturnClicked)="this.setReturn()"
    >
    </app-wdf-field-confirmation>
    <app-wdf-warning-message
      *ngIf="workplace.wdf?.leavers?.isEligible === Eligibility.NO"
      [overallWdfEligibility]="overallWdfEligibility"
      data-testid="leaversWdfWarning"
    ></app-wdf-warning-message>
  </ng-container>

  <ng-container>
    <div class="govuk-summary-list__row" data-testid="recruitment-and-benefits-section">
      <dt class="govuk-summary-list__key">
        <h2 class="govuk-heading-m govuk-!-margin-bottom-2 govuk-!-margin-top-5 govuk__nowrap">
          Recruitment and benefits
        </h2>
      </dt>
      <dd class="govuk-summary-list__value"></dd>
      <dd class="govuk-summary-list__actions"></dd>
    </div>

    <div class="govuk-summary-list__row" data-testid="repeat-training">
      <dt class="govuk-summary-list__key">Repeat training</dt>
      <dd class="govuk-summary-list__value">
        <ng-container
          *ngIf="
            !workplace.doNewStartersRepeatMandatoryTrainingFromPreviousEmployment;
            else doNewStartersRepeatMandatoryTrainingFromPreviousEmploymentType
          "
        >
          -
        </ng-container>
        <ng-template #doNewStartersRepeatMandatoryTrainingFromPreviousEmploymentType>
          {{ workplace.doNewStartersRepeatMandatoryTrainingFromPreviousEmployment }}
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' repeat training'"
          [link]="['/workplace', workplace.uid, 'staff-recruitment-capture-training-requirement']"
          [hasData]="workplace.doNewStartersRepeatMandatoryTrainingFromPreviousEmployment"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>

    <div class="govuk-summary-list__row" data-testid="accept-care-certificate">
      <dt class="govuk-summary-list__key">Accept Care Certificate</dt>
      <dd class="govuk-summary-list__value">
        <ng-container
          *ngIf="
            !workplace.wouldYouAcceptCareCertificatesFromPreviousEmployment;
            else wouldYouAcceptCareCertificatesFromPreviousEmploymentType
          "
        >
          -
        </ng-container>
        <ng-template #wouldYouAcceptCareCertificatesFromPreviousEmploymentType>
          {{ workplace.wouldYouAcceptCareCertificatesFromPreviousEmployment }}
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' accept care certificate'"
          [link]="['/workplace', workplace.uid, 'accept-previous-care-certificate']"
          [hasData]="workplace.wouldYouAcceptCareCertificatesFromPreviousEmployment"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>

    <div class="govuk-summary-list__row" data-testid="cash-loyalty-bonus-spend">
      <dt class="govuk-summary-list__key">Cash loyalty bonus</dt>
      <dd class="govuk-summary-list__value">
        <ng-container
          *ngIf="!workplace.careWorkersCashLoyaltyForFirstTwoYears; else careWorkersCashLoyaltyForFirstTwoYearsType"
        >
          -
        </ng-container>
        <ng-template #careWorkersCashLoyaltyForFirstTwoYearsType>
          <ng-container
            *ngIf="
              workplace.careWorkersCashLoyaltyForFirstTwoYears == 'No' ||
                workplace.careWorkersCashLoyaltyForFirstTwoYears == 'Don\'t know' ||
                workplace.careWorkersCashLoyaltyForFirstTwoYears == 'Yes';
              then careWorkersCashLoyaltyForFirstTwoYearsNotKnown;
              else careWorkersCashLoyaltyForFirstTwoYearsKnown
            "
          >
          </ng-container>
          <ng-template #careWorkersCashLoyaltyForFirstTwoYearsNotKnown>
            {{ workplace.careWorkersCashLoyaltyForFirstTwoYears }}
          </ng-template>
          <ng-template #careWorkersCashLoyaltyForFirstTwoYearsKnown>
            £{{ this.formatMonetaryValue(workplace.careWorkersCashLoyaltyForFirstTwoYears) }}
          </ng-template>
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' loyalty bonus'"
          [link]="['/workplace', workplace.uid, 'cash-loyalty']"
          [hasData]="workplace.careWorkersCashLoyaltyForFirstTwoYears"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>

    <div class="govuk-summary-list__row" data-testid="offer-more-than-statutory-sick-pay">
      <dt class="govuk-summary-list__key">Offer more than Statutory Sick Pay</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.sickPay; else sickPayType"> - </ng-container>
        <ng-template #sickPayType>
          {{ workplace.sickPay }}
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' statutory sick pay'"
          [link]="['/workplace', workplace.uid, 'benefits-statutory-sick-pay']"
          [hasData]="workplace.sickPay"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>

    <div class="govuk-summary-list__row" data-testid="higher-pension-contributions">
      <dt class="govuk-summary-list__key">Higher pension contributions</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.pensionContribution; else pensionContributionType"> - </ng-container>
        <ng-template #pensionContributionType>
          {{ workplace.pensionContribution }}
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' pension'"
          [link]="['/workplace', workplace.uid, 'pensions']"
          [hasData]="workplace.pensionContribution"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>

    <div class="govuk-summary-list__row" data-testid="number-of-days-leave">
      <dt class="govuk-summary-list__key">Number of days leave</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.careWorkersLeaveDaysPerYear; else careWorkersLeaveDaysPerYearType">
          -
        </ng-container>
        <ng-template #careWorkersLeaveDaysPerYearType>
          {{ workplace.careWorkersLeaveDaysPerYear }}
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' holiday leave'"
          [link]="['/workplace', workplace.uid, 'staff-benefit-holiday-leave']"
          [hasData]="workplace.careWorkersLeaveDaysPerYear"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
  </ng-container>

  <ng-container>
    <div class="govuk-summary-list__row" data-testid="permissions-section">
      <dt class="govuk-summary-list__key">
        <h2 class="govuk-heading-m govuk-!-margin-bottom-2 govuk-!-margin-top-5">Permissions</h2>
      </dt>
      <dd class="govuk-summary-list__value"></dd>
      <dd class="govuk-summary-list__actions"></dd>
    </div>
    <div class="govuk-summary-list__row" data-testid="data-sharing">
      <dt class="govuk-summary-list__key">Data sharing</dt>
      <dd class="govuk-summary-list__value">
        <ng-container *ngIf="!workplace.shareWith?.cqc && !workplace.shareWith?.localAuthorities; else sharing">
          <ng-container
            *ngIf="
              workplace.shareWith?.cqc === false || workplace.shareWith?.localAuthorities === false;
              else notProvided
            "
          >
            Not sharing
          </ng-container>
          <ng-template #notProvided> - </ng-template>
        </ng-container>
        <ng-template #sharing>
          <p *ngIf="workplace.shareWith?.cqc">Care Quality Commission (CQC)</p>
          <p *ngIf="workplace.shareWith?.localAuthorities">Local authorities</p>
        </ng-template>
      </dd>
      <dd *ngIf="canEditEstablishment" class="govuk-summary-list__actions">
        <app-summary-record-change
          [explanationText]="' data sharing'"
          [link]="['/workplace', workplace.uid, 'sharing-data']"
          [hasData]="workplace.shareWith?.cqc !== null || workplace.shareWith?.localAuthorities !== null"
          (setReturnClicked)="this.setReturn()"
        ></app-summary-record-change>
      </dd>
    </div>
  </ng-container>
</dl>

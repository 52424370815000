<ng-container *ngIf="certificates?.length > 0 || filesToUpload?.length > 0">
  <table class="govuk-table">
    <thead class="govuk-table__head" data-testid="certificationsTableHeader">
      <tr class="govuk-table__row">
        <th scope="col" class="govuk-table__header govuk-!-padding-bottom-1 filename-table-header">File name</th>
        <th scope="col" class="govuk-table__header govuk-!-padding-bottom-1">Upload date</th>
        <th scope="col" class="govuk-table__header govuk-!-font-weight-regular govuk-!-padding-bottom-1">
          <ng-container *ngIf="certificates?.length > 1">
            <a
              href="#"
              class="govuk-link govuk-link--no-visited-state"
              (click)="handleDownloadCertificate($event, null)"
            >
              <img alt="" src="/assets/images/icon-download.svg" class="govuk-util__vertical-align-bottom" /><span
                class="govuk-!-margin-left-1"
                >Download all</span
              ></a
            >
          </ng-container>
        </th>
        <th scope="col" class="govuk-table__header govuk-!-padding-bottom-1"></th>
      </tr>
    </thead>
    <tbody class="govuk-table__body">
      <ng-container *ngFor="let file of filesToUpload; let i = index">
        <tr class="govuk-table__row" [attr.data-testid]="'upload-file-row-' + i">
          <td class="govuk-table__cell table__cell-file_name govuk-!-font-weight-regular">{{ file.name }}</td>
          <td class="govuk-table__cell table__cell-date">{{ dateToday | date: 'd MMM y' }}</td>
          <td class="govuk-table__cell table__cell-download_button"></td>
          <td class="govuk-table__cell table__cell-cancel_button">
            <a
              href="#"
              type="button"
              class="govuk-link govuk-link--no-visited-state"
              (click)="handleRemoveUploadFile($event, i)"
            >
              Remove <span class="govuk-visually-hidden">{{ file.name + ' from the files to be uploaded' }}</span>
            </a>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let certificate of certificates; let i = index">
        <tr class="govuk-table__row" [attr.data-testid]="'certificate-row-' + i">
          <td class="govuk-table__cell table__cell-file_name govuk-!-font-weight-regular">
            {{ certificate.filename }}
          </td>
          <td class="govuk-table__cell table__cell-date">
            {{ certificate.uploadDate | date: "d MMM y, h:mmaaaaa'm'" }}
          </td>
          <td class="govuk-table__cell table__cell-download_button">
            <a href="#" class="govuk-link govuk-link--no-visited-state" (click)="handleDownloadCertificate($event, i)">
              <img alt="" src="/assets/images/icon-download.svg" class="govuk-util__vertical-align-bottom" /><span
                class="govuk-!-margin-left-1"
                >Download</span
              >
              <span class="govuk-visually-hidden">the certificate {{ certificate.filename }}</span>
            </a>
          </td>
          <td class="govuk-table__cell table__cell-cancel_button">
            <a
              href="#"
              type="button"
              class="govuk-link govuk-link--no-visited-state"
              (click)="handleRemoveSavedFile($event, i)"
              >Remove <span class="govuk-visually-hidden">the uploaded certificate {{ certificate.filename }}</span></a
            >
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
